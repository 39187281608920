import { Basket, Order } from '../client';
import Cookie from 'js-cookie';

const BASKET_COOKIE = "shop-basket"
const ORDER_COOKIE = "shop-order"
const MC_POPUP_COOKIE = "MCPopupfemSense"
const COUNTRY_COOKIE = "user-geoip-country"
const COUPON_CODE = "discount"
const BASKET_ITEM_COUNT = "shop-basket-item-count"
const TERRITORY = "location"


const defaults = {
  expires: 1,
  secure: true,
}

export const getBasket = (): Basket | null => JSON.parse(Cookie.get(BASKET_COOKIE) ?? "null");
export const setBasket = (basket: Basket | null) => {
  if(basket) {
    const b = { uuid: basket.uuid }
    Cookie.set(BASKET_COOKIE, JSON.stringify(b), defaults);
  } else {
    Cookie.remove(BASKET_COOKIE)
  }
};

export const getOrder = (): Order | null => JSON.parse(Cookie.get(ORDER_COOKIE) ?? "null");
export const setOrder = (order: Order | null) => {
  if(order) {
    const o = { uuid: order.uuid }
    Cookie.set(ORDER_COOKIE, JSON.stringify(o), defaults);
  } else {
    Cookie.remove(ORDER_COOKIE)
  }
};

export const getNewsletter = () => Cookie.get(MC_POPUP_COOKIE);
export const setNewsletter = () => Cookie.set(MC_POPUP_COOKIE, 'yes', {...defaults, expires: 7 });

export const getLocation = () => Cookie.get(TERRITORY);
export const setLocation = (hhref: string) => Cookie.set(TERRITORY, hhref, {...defaults, expires: 14});
export const clearLocation = () => Cookie.set(TERRITORY,'',{...defaults, expires: -1});

export const getGeoIpCountry = () => Cookie.get(COUNTRY_COOKIE);
export const setGetIpCountry = (code?: string | null) => {
  console.log(`GeoIP Country set to ${code}`);
  if (code) {
    Cookie.set(COUNTRY_COOKIE, code, defaults);
  } else {
    Cookie.remove(COUNTRY_COOKIE);
  }
}

export const getCoupon = () => Cookie.get(COUPON_CODE);
export const setCoupon = (code?: string | null) => {
  if (code) {
    Cookie.set(COUPON_CODE, code, {...defaults, expires: 3 });
  } else {
    Cookie.remove(COUPON_CODE)
  }
}

export const getBasketItemCount = () => Cookie.get(BASKET_ITEM_COUNT)
export const setBasketItemCount = (itemCount: number | string | null) => {
  if (itemCount != null) {
    Cookie.set(BASKET_ITEM_COUNT, itemCount.toString(), defaults);
  } else {
    Cookie.remove(BASKET_ITEM_COUNT);
  }
}
