import * as _cookies from './cookies';

export function getQueryString(name: string) {
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(name);
  if (value) {
    return value;
  }
  throw new Error(`QueryString for ${name} not found`);
}

export function getParents(elem: HTMLElement, selector?: string): HTMLElement[] {
  const parents = [elem];
  for (let e=elem; e.parentElement != null; e = e.parentElement) {
    if((selector && e.matches(selector)) || !selector){
      parents.push(e)
    }
  }
  return parents;
}

export const getScrollPosY = () => {
  return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || window.scrollY || 0;
}

export const textToClipboard = (text: string) => {
  const input = document.createElement('input');
  document.body.appendChild(input);
  input.setAttribute('value', text);
  input.select();
  const result = document.execCommand('copy');
  input.remove();
  return result;
};

export const cookies = _cookies;


export const isXDefault = () => {
  return document.querySelector("[hreflang='x-default']")?.getAttribute("href") === window.location.href.replace(window.location.search, "")
}

export const getHrefLang = () => {
  return isXDefault() ? "x-default" : document.documentElement.lang;
}

/**
 * Takes a price string and returns a number: '€ 24,20' -> 24.2
 * @param price
 */
export const priceToNumber = (price: string) : number => {
  return Number.parseFloat(price.replace(/,/, '.').replace(/[^0-9\.-]+/g, ''))
}

