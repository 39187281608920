// CSS and SASS files
import './index.scss';
import './data/sentry';
import 'lazysizes';
// import 'cookieconsent';
import smoothscroll from 'smoothscroll-polyfill';
import './components/navigation';
import './components/analytics';
import 'preact/debug';
import { textToClipboard, cookies } from './utils';
// import { initZendesk } from './components/zendesk';
import { displayMailChimpStatus, initMailchimp } from './components/mailchimp';
// import { initTerritorySelector } from './components/localization';
import { initFaqs } from './components/faq';
import { initSentry } from './sentry';
import { setBasketItemCount } from './components/itemcounter';
import Cookie from 'js-cookie';


const checkout = () => {
  const checkoutContainer = document.querySelector('.checkout-container');
  if (checkoutContainer) {
    return import('./checkout')
      .then(lib => lib.default(checkoutContainer))
  }
}

const catalogue = () => {
  const catalogueContainer = document.querySelector('.shop-container');
  if (catalogueContainer) {
    return import('./catalogue')
      .then(lib => lib.default(catalogueContainer))
  }
}

/*
if (module.hot) {
  module.hot.accept(['./catalogue', './checkout'], (updatedDependencies) => {
    console.log("HMR: ", updatedDependencies);
    catalogue();
    checkout();
    console.log("HMR finished");
  });
}
 */

interface LegalDocument {
  filename: string
  path: "legal",
  type: "ifu" | "recycling",
  style: "new",
  project?: string,
  language: "de" | "en" | "fr" | "es",
  year: string,
  month: string,
}

interface StoreUrls {
  googlePlay: string;
  iTunes: string;
}

interface PageUrls {
  [key: string]: string
}

interface Navigator {
  userLanguage?: string;
  browserLanguage?: string;
  languages: readonly string[];
  language: string;
}

declare global {
  interface Window {
    storeUrls: StoreUrls,
    pageUrls: PageUrls,
    downloadRedirect?: boolean
    opera?: string;
    MSStream?: string;
    api: any;

    displayMailChimpStatus: (...a: unknown[]) => unknown;
  }
}

export type MobileOs = "Windows Phone" | "Android" | "iOS" | "unknown";
export type IsoLanguage = "en" | "de" | "es" | "fr";


function getMobileOperatingSystem(): MobileOs {
  const userAgent = (navigator.userAgent || navigator.vendor || window.opera) ?? "";
  switch(true) {
    // Windows Phone must come first because its UA also contains "Android"
    case /windows phone/i.test(userAgent):
      return 'Windows Phone';
    case /android/i.test(userAgent):
        return 'Android';
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
    case (/iPad|iPhone|iPod/.test(userAgent)
      || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
      && !window.MSStream:
        return 'iOS';
    default:
      console.warn(`Unable to detect mobile OS from User-Agent ${userAgent}`);
      return 'unknown';
  }
}

function redirectAppStore(storeUrls: StoreUrls): void {
  const os = getMobileOperatingSystem();
  switch(true) {
    case os === 'Android' && storeUrls.googlePlay !== '':
      return window.location.replace(storeUrls.googlePlay);
    case os === 'iOS' && storeUrls.iTunes !== '':
        return window.location.replace(storeUrls.iTunes);
    default:
      return
  }
}


function getUserLanguage(): IsoLanguage | string {
  const navigator: Navigator = window.navigator;
  const lang = navigator.userLanguage || navigator.language;
  switch(true){
    case /en/i.test(lang): return 'en';
    case /de/i.test(lang): return 'de';
    case /fr/i.test(lang): return 'en';
    case /es/i.test(lang): return 'de';
    default: return lang
  }
}

function redirectLanguage() {
  const lang = getUserLanguage();
  const url = window.pageUrls[lang];

  if (url !== undefined && window.location.pathname !== url) {
    window.location.replace(url);
  } else if (url === undefined || window.location.pathname === url) {
    redirectAppStore(window.storeUrls);
  }
}

//
// DOWNLOAD
//
if (window.downloadRedirect === true) {
  redirectLanguage();
}


//
// KINDERWUNSCH INSTRUCTIONS VIDEO
//
const initModalVideo = () => {
  const videoElements = document.querySelectorAll('.js-modal-btn');
  if (videoElements.length) {
    const cfg = {
      youtube: {
        rel: 0,
        showinfo: 0,
        modestbranding: 1,
        iv_load_policy: 3,
      },
    };
    return import('./components/ModalVideo')
      .then(lib => new lib.default(videoElements, cfg))
      .then(() => console.log("Imported modal video dynamically"))
      .catch(err => console.error(`Failed to load modal video ${err.name}: ${err.message}`));
  }
}

const initCarousel = () => {
  const carouselConfigs = {
    '#carousel-demo': {
      slidesToScroll: 1,
      slidesToShow: 4,
      navigationSwipe: true,
    },
    '#carousel-home': {
      slidesToScroll: 1,
      slidesToShow: 3,
      navigationSwipe: true,
      loop: true,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 2500,
    },
    '#carousel-fertility': {
      slidesToScroll: 1,
      slidesToShow: 1,
      navigationSwipe: true,
      loop: true,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 2500,
    },
    '.carousel-press': {
      slidesToScroll: 1,
      slidesToShow: 1,
      navigationSwipe: true,
      loop: true,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 2500,
    },
    '#carousel-quotation': {
      slidesToScroll: 1,
      slidesToShow: 3,
      navigationSwipe: true,
      loop: true,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 2500,
    }
  };
  const carousels = Object.entries(carouselConfigs)
    .map(([k, v]) => [document.querySelector(k), v])
    .filter(([k, v]) => Boolean(k));

  console.log("Init caroussels", carousels);
  if (carousels.length) {
    return import('./components/Carousel')
      .then(lib =>
        carousels.forEach(([k, v]) => lib.default(k, v))
      )
      .catch(err => console.error(`Failed to load carousel ${err.name}: ${err.message}`));
  }
}

// Init Modal view for Success Stories
const openModal = (e: Event) => {
  if (e.currentTarget instanceof HTMLElement) {
    const id = e.currentTarget.dataset['modal'];
    const t = id ? document.getElementById(id) : null;
    t?.classList.add('is-active');
    document.documentElement.classList.add('is-clipped');
  }
}

const closeModal = (e: Event) => {
  if (e.currentTarget instanceof HTMLElement) {
    e.currentTarget.closest(".modal")?.classList.remove('is-active')
    document.documentElement.classList.remove('is-clipped');
  }
}

const initModals = () => {
  document.querySelectorAll('.stories-modal').forEach(n => n.addEventListener('click', openModal));
  document.querySelectorAll('.modal-close').forEach(n => n.addEventListener('click', closeModal));
}

const initFaqLinks = () => {
  document.querySelectorAll('a.question-anchor')
    .forEach((anchor) => {
      anchor.addEventListener('click', () => {
        const page = window.location.href.replace(/#.*$/, '');
        textToClipboard(page + anchor.getAttribute("href"));
      });
    });
}

function getLanguageFromLocation() {
  const lang = window.location.pathname.split('/')[1];
  return lang === 'ifu' ? 'en' : lang;
}

function appendData(data: LegalDocument[], lang: string) {
  const pdfPath = `${LEGAL_FILE}`.replace('/legal.json', '');
  const newlink = document.getElementById('newlink');
  const oldlink = document.querySelector('.oldlink');
  const versionText = document.querySelector('.version-text');

  data.filter(d => d.language === lang && d.type === 'ifu')
    .forEach((d, i) => {
      (i === 0 ? newlink : oldlink)?.setAttribute('href', `${pdfPath}/${d.filename}`);
      if (versionText) {
        versionText.textContent = '1.3.5'; // d.version
      }
    })
}

const initIfuLinks = () => {
  if (document.querySelector('#ifu')) {
    const lang = getLanguageFromLocation();
    fetch(`${LEGAL_FILE}`)
      .then(response => response.json())
      .then(data => appendData(data, lang))
      .catch(e => console.error(`Failed to get legal file ${LEGAL_FILE}: ${e.name}: ${e.message}`));
  }
}

const initMarquee = (country: string) => {
  const marquee = document.querySelector('#marquee');
  console.info('Marquee initializing...');
  if (marquee && marquee instanceof HTMLElement) {
    console.info('Marquee initializing2...');
    const dcountries = marquee.dataset.marquee;
    if (dcountries?.includes(country)) {
      marquee.classList.remove('is-hidden');
      console.info('Marquee executed');
    }
  }
}

const initBanner = (country: string) => {
  const topbanner = document.querySelector('#topbanner');
  const topbanner2 = document.querySelector('#topbanner2');
  const spanbanner2 = document.querySelector('#spanbanner2');
  if (topbanner && topbanner instanceof HTMLElement) {
    const dcountries = topbanner.dataset.banner;

    if (topbanner.dataset.text !== ' ') {
      if (dcountries?.includes(country)) {
        topbanner.classList.remove('is-hidden');
        topbanner2?.remove();
      } else if (topbanner2 && topbanner2 instanceof HTMLElement) {
        if (country === 'GB' && spanbanner2 && spanbanner2 instanceof HTMLElement) {
          spanbanner2.innerText = 'SALE: up to -25%';
        }
        topbanner2.classList.remove('is-hidden');
        topbanner.remove()
      }
    }
  }
};



// Newsletter
const showNewsletterPopup = () => {
  const m = document.getElementById('mailchimp-modal');
  if (m) {
    m.classList.add('is-active');
    cookies.setNewsletter();
  }
}

const initNewsletter = () => {
  /*   -- for newsletter sign up campaign
  if (!cookies.getNewsletter()) {
    setTimeout(showNewsletterPopup, 15000);
  }
  */
  showNewsletterPopup();
  document.querySelectorAll('.subscribeNewsletter')
    .forEach((e) => e.addEventListener("click", showNewsletterPopup));
};


const initBasket = async () => {
  const api  = await import('./data/api');
  const basket = cookies.getBasket();
  try {
    const response = await (
      basket?.uuid
        ? api.default.basketRead(basket.uuid)
        : api.default.basketCreate({ shipping: null, billing: null, items: [] })
    );
    cookies.setBasket(response.data)
    cookies.setGetIpCountry(response.data.country);
    setBasketItemCount(response.data.items?.length ?? 0)
  } catch (e) {
    console.error("Failed to read or create Basket", e);
  }
}

const initBlogBanner = (country: string) => {
  if (document.querySelector('.blog-banner')) {
    let useDefault = true;
    console.info('Country:' +  country);
    document.querySelectorAll('.blog-banner').forEach((e) => {
      if (e instanceof HTMLElement && e.dataset.countries && e.dataset.countries.includes(country)) {
        e.classList.remove('is-hidden');
        useDefault = false;
      }
    });
    if (useDefault) {
      console.info('default');
      document.querySelectorAll('.blog-banner.default').forEach((e) => {
        if (e instanceof HTMLElement) {
          e.classList.remove('is-hidden');
        }

      });
    }
  }
};

const initBlogButtons =(country:string) => {
  const BlogBtn = document.querySelector('.shop_btn');
  const dcountries = ['AT', 'DE'];
  let ttext = ['Get femsense', 'Order now', 'Shop now', 'Find out more'];
  const btnclass =['shop-btn1','shop-btn2','shop-btn3'];
  if (document.URL.includes('/de')) {
    ttext = ['Jetzt kaufen', 'Zum Shop', 'Shop', 'Jetzt entdecken'];
  }
  if (BlogBtn) {
    document.querySelectorAll('.shop_btn').forEach((e) => {
      e.innerHTML = ttext[ Math.floor(Math.random() * ttext.length ) ];
      e.classList.add(btnclass[Math.floor(Math.random()*btnclass.length)]);
    });
  }
}

const initSalesPopup = (country?: string) => {
  const subscribe = Cookie.get('SalesPopupfemSense');
  // replace subscribe with line above, & Timeout value set to 10000 instead of 1000
  // set cookie to 5h
  // const subscribe = 0;
  const mchimp = document.querySelector<HTMLElement>('#salespopup-modal');

  const dcountries = mchimp?.dataset.countries;
  if (!country || !dcountries || !dcountries.includes(country)) {
    return;
  }

  if (!subscribe && !document.URL.includes('shop')) {
    setTimeout(() => {
      const m = document.getElementById('salespopup-modal');
      if (m) {
        m.classList.add('is-active');
      }
    }, 1000);
    const btn = document.querySelector('.modal-close.mc');
    if (btn) {
      btn.addEventListener(
        'click',
        () => {
          // Cookie.set('MCPopupfemSense', 'yes', { expires: 7 });
          Cookie.set('SalesPopupfemSense', 'yes');
        },
        { passive: true },
      );
    }
  }
};


const initLocationDependentElements = () => {
  const country = cookies.getGeoIpCountry();
  if(!country) {
    console.debug("Country cookie not set, retry in 3 seconds");
    setTimeout(initLocationDependentElements, 3000);
    return
  }
  initBanner(country);
  initMarquee(country);
  initBlogBanner(country);
  initMailchimp(country);
  initSalesPopup(country);
  initBlogButtons(country);
}

const initShopBannerPopup = () => {
  const showBanner = document.getElementById('shop_banner_popup');
  if (showBanner) {
    showBanner.classList.remove('is-hidden');
  }
}

const initShopBanner = (retryDelayMs: number=5000) => {
  const url = document.URL;
  if (url.includes('blog')) {
    setTimeout(initShopBannerPopup, retryDelayMs);
    let bannerClose = document.getElementById('shop-banner-close');
    if(bannerClose) {
      bannerClose.addEventListener("click",(e) => {
        const bbanner = document.getElementById('shop_banner_popup');
        if(bbanner) {
          bbanner.classList.add('is-hidden');
        }
      });
    }


  }
}



(async () => {


  if (SENTRY_ENVIRONMENT !== "development") {
    initSentry(`${SENTRY_DSN}`, `${SENTRY_ENVIRONMENT}`);
  }

  const url = document.URL;
  const checkoutPromise = checkout();
  const cataloguePromise = catalogue();
  const basketPromise = (!cookies.getGeoIpCountry() && !checkoutPromise && !cataloguePromise) ? await initBasket() : null;
/*
  if(initTerritorySelector() === "visible") {
    return;
  }*/

  initModals();
  //  initNewsletter();
  initFaqLinks();
  initIfuLinks();
  await initCarousel();
  initModalVideo();
/*
  if (!url.includes('shop') && !url.includes('blog') && !url.includes('checkout'))
  {
    initZendesk(document.documentElement.getAttribute('lang'));
  }
*/
  initShopBanner();
  // initMailchimp();
  initFaqs();
  setBasketItemCount(cookies.getBasketItemCount() ?? 0);
  smoothscroll.polyfill()

  initLocationDependentElements();


  let changeLocationLink = document.getElementById('region');
  let changeLocationLink1 = document.getElementById('region1');
  if (changeLocationLink) {
    changeLocationLink.addEventListener('click', (event) => {
      event.preventDefault();
      cookies.clearLocation();
      window.location.href = window.location.origin;
    });
  }
  if (changeLocationLink1) {
    changeLocationLink1.addEventListener('click', (event) => {
      event.preventDefault();
      cookies.clearLocation();
      window.location.href=window.location.origin;
    });
  }

  await Promise.all(
    [checkoutPromise, cataloguePromise, basketPromise].filter(p => Boolean(p))
  );

  console.log("Async init finished");
})();
