import Houdini from 'houdinijs/dist/js/houdini.polyfills';
import { getParents } from '../utils';

//
// NAVIGATION MENU
//

// remove navbar on main screen

// toggle menu with menu-icon
if (document.querySelector('.navbar-burger')) {
  document.querySelector('.navbar-burger').addEventListener('click', (e) => {
    e.preventDefault();
    document.querySelector('.navbar').classList.toggle('is-active');
    document.querySelector('.navbar-burger').classList.toggle('is-active');
    document.querySelector('#navbarMain').classList.toggle('is-active');
    document.querySelector('.navbar__logo').classList.toggle('navbar__logo--inverted');
  });
}
//
// shrink navbar on scrolling
const navbar = document.querySelector('.navbar');
const navcontainer = document.querySelector('.nav-container');

const shrinkOnScroll = () => {
  const banner = document.querySelector('.banner-home');
  const srcbody = document.body.scrollTop;
  const scrdocu = document.documentElement.scrollTop;

  if (srcbody > 50 || scrdocu > 50) {
    navbar.classList.add('navbar__shrink');
    navcontainer.classList.add('container__shrink');
    if (banner) {
      banner.classList.add('banner-home-shrink');
    }
  } else {
    navbar.classList.remove('navbar__shrink');
    navcontainer.classList.remove('container__shrink');
    if (banner) {
      banner.classList.remove('banner-home-shrink');
    }
  }
};

if (false) {
  // Disabled shrink
  // TODO: Investigate position: fixed & transform chrome bug
  window.onscroll = shrinkOnScroll;
}

//
// LANG SWITCHER
//
/*
if (document.querySelector('.locale-dropdown')) {
  document.querySelector('.locale-dropdown .dropdown-trigger').addEventListener('click', () => {
    if (document.querySelector('.locale-dropdown').classList.contains('is-active')) {
      document.querySelector('.locale-dropdown').classList.remove('is-active');
    } else {
      document.querySelector('.locale-dropdown').classList.add('is-active');
    }
  });
}
 */

//
// Add query params to language switcher
// prevents checkout from loosing products, coupons and other stuff
//

const elements = document.querySelectorAll('.dropdown-item > a');
Array.prototype.forEach.call(elements, (el) => {
  // eslint-disable-next-line no-param-reassign
  el.href += window.location.search;
});

//
// Initialize accordions
//
const accordion = new Houdini('[data-houdini-group]', {
  isAccordion: true,
});

// Open accordion from URL hash
if (window.location.hash) {
  const hash = window.location.hash.substring(1);
  const hashEl = document.getElementById(hash);

  if (hashEl) {
    // Expand target element
    accordion.expand(`#${hash}`);

    // Expand parents
    const parents = getParents(hashEl, '[data-houdini-group]');
    Array.prototype.forEach.call(parents, (el) => {
      accordion.expand(el);
    });

    // Scroll to element
    document.getElementById(hash).scrollIntoView();
  }
}
