import { Basket, BasketItem, Order, OrderItem } from '../client';

const FACEBOOK_ENABLED = false;


const sendToGoogle = (
  eventName: Gtag.EventNames | string,
  eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams,
) => {
  // must handle those events: https://developers.google.com/gtagjs/reference/ga4-events
  /*global gtag */
  /*global dataLayer */
  if (window.dataLayer) {
    // gtag('event', eventName, eventParams);
    dataLayer?.push({ ecommerce: null });
    dataLayer?.push({
      event: eventName,
      ecommerce: eventParams,
    });
    console.debug(`Sent google event ${eventName}`);
  } else {
    console.debug(`Skipped sending google event ${eventName}`);
  }
}

const sendToFb = (
  eventName: string,
  params: facebook.Pixel.PurchaseParameters | facebook.Pixel.AddToCartParameters | facebook.Pixel.InitiateCheckoutParameters,
) => {
  /*global fbq */
  if (FACEBOOK_ENABLED && typeof window.fbq !== 'undefined') {
    fbq("track", eventName, params)
    console.debug(`Sent fb event ${eventName}`);
  } else {
    console.debug(`Skipped sending fb event ${eventName}`);
  }
}

const sendToPinterest = (eventType: string, eventName: string, params: any) => {
  /* global pintrk */
  if (typeof window.pintrk !== 'undefined') {
    pintrk(eventType, eventName, params);
    console.debug(`Sent pinterest event ${eventName}`);
  } else {
    console.debug(`Skipped sending pinterest event ${eventName}`);
  }
}

const getProductIds = (obj: Order|Basket): string[] => {
  const items: Array<OrderItem|BasketItem> = obj.items ?? [];
  return items.filter(i => i.content_type === "SKU").map(i => i.content_object!) ?? []
}

const getShipingCost = (obj: Basket | Order): number => {
  if ("selected_shipping_option" in obj){
    return Number(obj.selected_shipping_option?.amount_gross ?? 0);
  }
  else {
    const items: Array<OrderItem|BasketItem> = obj.items ?? [];
    return items.filter(i => i.content_type === "Shipping").map(i => Number(i.amount_gross ?? 0)).filter(Boolean).reduce((a, b) => a + b, 0) ?? 0;
  }
}

const getDiscount = (obj: Basket | Order): string => {
  if ("voucher_code" in obj) {
    return obj.voucher_code ?? ""
  } else {
    const items: Array<OrderItem|BasketItem> = obj.items ?? [];
    return items.find(i => i.content_type === "Voucher")?.description ?? "";
  }
}

const getGoogleProducts = (obj: Order | Basket) => {
  const items: Array<OrderItem|BasketItem> = obj.items ?? [];
  return items.filter(i => i.content_type === "SKU").map(i => ({
    item_id: i.content_object,
    item_name: i.name,
    item_currency: i.currency,
    quantity: i.quantity,
    price: Number(i.amount_gross),
  })) ?? []
}

const getPinterestProducts = (order: Order) => {
  return order.items?.filter(i => i.content_type === "SKU").map(i => ({
    product_name: i.name,
    product_price: Number(i.amount_gross),
    product_quantity: i.quantity,
  }));
}

export const checkoutInitiated = (basket: Basket) => {
  // see: https://developers.google.com/gtagjs/reference/ga4-events#begin_checkout
  sendToGoogle("begin_checkout", {
    currency: basket.currency,
    value: Number(basket.amount_gross),
    coupon: basket.voucher_code,
    items: getGoogleProducts(basket)
  })
}

export const purchase = (order: Order) => {
  // see https://developers.google.com/analytics/devguides/collection/ga4/ecommerce#purchase
  // see https://developers.google.com/tag-manager/ecommerce-ga4?authuser=1#measure_purchases
  // see https://developers.google.com/gtagjs/reference/ga4-events#purchase
  sendToGoogle("purchase", {
    currency: order.currency,
    transaction_id: order.order_number,
    value: Number(order.amount_gross),
    coupon: getDiscount(order),
    shipping: getShipingCost(order),
    items: getGoogleProducts(order),
  });

  const fbIds = getProductIds(order);
  const fbParams: facebook.Pixel.PurchaseParameters = {
    content_ids: fbIds,
    content_type: 'product',
    num_items: fbIds.length,
    currency: order.currency!,
    value: Number(order.amount_gross),
  }
  // sendToFb(`Purchase`, fbParams);

  // Pinterest Checkout event description: Track people who complete transactions.
  // see https://help.pinterest.com/en-gb/business/article/add-event-codes#section-16176
  sendToPinterest('track', 'checkout', {
    value: Number(order.amount_gross),
    order_quantity: fbIds.length,
    currency: order.currency,
    line_items: getPinterestProducts(order),
  });

  /* try {
    // Track piwik for TV ads
    const url = '//trck.spoteffects.net/analytics/';
    /* eslint-disable
    window._spef = [];
    window._spef.push(['trackEcommerceOrder', `${orderId}`, total * 0.01]);
    window._spef.push(['enableLinkTracking']);
    window._spef.push(['trackPageView']);
    window._spef.push(['setTrackerUrl', `${url}piwik.php`]);
    window._spef.push(['setSiteId', 868]);
    /* eslint-disable
    const g = document.createElement('script');
    g.type = 'text/javascript';
    g.defer = true;
    g.async = true;
    g.src = `${url}spef.min.js`;

    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(g, s);
    console.log('P Executed');
  } catch (e) {
    console.error('Failed to track piwik', e);
  }
  */
};

export const addPaymentInfo = (basket: Basket) => {
  // see: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce#add_payment_info
  sendToGoogle("add_payment_info", {
    currency: basket.currency,
    value: Number(basket.amount_gross),
    coupon: basket.voucher_code,
    payment_type: basket.selected_payment_option?.name ?? "Error getting selected payment option",
    items: getGoogleProducts(basket)
  })

  const fbIds = getProductIds(basket)
  const fbData: facebook.Pixel.AddPaymentInfoParameters = {
    value: Number(basket.amount_gross),
    currency: basket.currency ?? "Currency not found",
    content_ids: fbIds,
  }
  // sendToFb( 'AddPaymentInfo', fbData);
}


export const addShippingInfo = (basket: Basket) => {
  // see: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce#add_shipping_info
  sendToGoogle("add_shipping_info", {
    currency: basket.currency,
    value: Number(basket.amount_gross),
    coupon: basket.voucher_code,
    shipping_tier: basket.selected_shipping_option?.type ?? "Error getting selected shipping option",
    items: getGoogleProducts(basket),
  })
}

export const addToCart = (item: BasketItem) => {
  // https://developers.google.com/gtagjs/reference/ga4-events#add_to_cart
  const googleData: Gtag.EventParams = {
    value: Number(item.amount_gross),
    currency: item.currency,
    items: [
      {
          id: item.content_object ?? "SKU not found",
          name: item.name ?? "Unknown Name",
          quantity: item.quantity,
      }
    ],
  };
  sendToGoogle("add_to_cart", googleData)

  const fbData: facebook.Pixel.AddToCartParameters = {
    content_type: 'product',
    content_ids: [item.content_object ?? "SKU not found"],
    currency: 'EUR',
    content_name: item.name ?? "Unknown Name",
    value: Number(item.amount_gross)
  };
  // sendToFb("AddToCart", fbData);
};


export const removeFromCart = (item: BasketItem) => {
  // https://developers.google.com/gtagjs/reference/ga4-events#remove_from_cart
  const googleData: Gtag.EventParams = {
    value: Number(item.amount_gross),
    currency: item.currency,
    items: [
      {
          id: item.content_object ?? "SKU not found",
          name: item.name ?? "Unknown Name",
          quantity: item.quantity,
      }
    ],
  };
  sendToGoogle("remove_from_cart", googleData)
};
