const copyIt = (text: string) => {
  const input = document.body.appendChild(document.createElement('input'));
  input.setAttribute('value', text);
  input.select();
  const result = document.execCommand('copy');
  document.body.removeChild(input);
  return result;
}

export const initFaqs = () => {
  document.querySelectorAll('a[id=copylink]').forEach((anchor) => {
    anchor.addEventListener('click', () => {
      const page = window.location.href.replace(/#.*$/, '');
      // @ts-ignore
      copyIt(page + anchor.name);
    }, { passive: true });
  });
}
